
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface creditCard {
  creditCardBalance: number;
  minAmountMonthly: number;
  minPrcntgMonthly: number;
  interestRate: number;
}

export default defineComponent({
  name: "credit-card",
  components: {
    ErrorMessage,
    Field,
    Form,
    // ElCurrencyInputNoSymbol,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const totalInterest = 0;
    const totalPayment = 0;
    const payoffMonths = 0;
    const submitButton1 = ref<HTMLElement | null>(null);
    const creditCardCalculated = false;

    const paymentSchedule = {
      month: "",
      monthlyPayment: 0,
      interest: 0,
      principal: 0,
      balance: 0,
    };

    const paymentScheduleMonthy = [];

    return {
      id: route.params.id ?? null,
      store: useStore(),
      totalInterest,
      totalPayment,
      payoffMonths,
      submitButton1,
      creditCard: {
        creditCardBalance: 10000,
        minAmountMonthly: 50,
        minPrcntgMonthly: 5,
        interestRate: 18,
      },
      paymentSchedule,
      creditCardCalculated,
      paymentScheduleMonthy,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const creditCardValidator = Yup.object({
      raw: Yup.object({
        creditCardBalance: Yup.string().required().label("Car Price"),
        minAmountMonthly: Yup.string().required().label("Down payment"),
        minPrcntgMonthly: Yup.number().required().label("Loan Period"),
        interestRate: Yup.number().required().label("Interest Rate"),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Credit Card", ["Financial Calculators"]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const activeName = ref("stepUp1");

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      creditCardValidator,
      translate,
      nFormat,
      activeName,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          let creditCardBalance = this.store.getters.getCalculatorData.raw.creditCardBalance.replace(
            ",",
            ""
          );
          this.creditCard = this.store.getters.getCalculatorData.raw;
          this.creditCard.creditCardBalance = creditCardBalance;

          this.calculate();
        });
      }
    },
    calculate() {
      this.paymentSchedule = {
        month: "",
        monthlyPayment: 0,
        interest: 0,
        principal: 0,
        balance: 0,
      };

      this.paymentScheduleMonthy = [];

      this.totalInterest = 0;
      this.totalPayment = 0;
      this.payoffMonths = 0;

      let interest =
        (this.creditCard.creditCardBalance *
          (this.creditCard.interestRate / 100)) /
        12;
      let monthlyPayment =
        (this.creditCard.creditCardBalance * this.creditCard.minPrcntgMonthly) /
        100;

      if (this.creditCard.minAmountMonthly > monthlyPayment) {
        monthlyPayment = this.creditCard.minAmountMonthly;
      }

      let principal = monthlyPayment - interest;
      let month = 1;
      let balance =
        this.creditCard.creditCardBalance + interest - monthlyPayment;

      for (let i = 0; i < 1000; i++) {
        this.paymentSchedule = {
          month: this.ordinal_suffix_of(month),
          monthlyPayment: parseFloat(monthlyPayment.toFixed(2)),
          interest: parseFloat(interest.toFixed(2)),
          principal: parseFloat(principal.toFixed(2)),
          balance: parseFloat(balance.toFixed(2)),
        };
        this.paymentScheduleMonthy.push(this.paymentSchedule);
        this.totalPayment += monthlyPayment;
        this.payoffMonths = month;

        if (balance <= 0) break;

        interest = (balance * (this.creditCard.interestRate / 100)) / 12;
        monthlyPayment = (balance * this.creditCard.minPrcntgMonthly) / 100;
        if (monthlyPayment < this.creditCard.minAmountMonthly) {
          monthlyPayment = this.creditCard.minAmountMonthly;
        }
        if (monthlyPayment > balance) {
          monthlyPayment = balance;
          balance = balance - monthlyPayment;
        } else {
          balance = balance + interest - monthlyPayment;
        }

        principal = monthlyPayment - interest;

        if (balance < 0.5) balance = 0.0;
        month++;
      }

      this.totalInterest =
        this.totalPayment - this.creditCard.creditCardBalance;
      this.creditCardCalculated = true;
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },
    onSubmit(values) {
      console.log(values);
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
